import React, { useState } from "react"
import axios from 'axios'
import { Link } from "gatsby"
import Layout from "../../../components/layout"
// import { Helmet } from 'react-helmet'
import SEO from "../../../components/seo"
import Img from "../../../components/image-transform"

const Churn = () => {
  const [onSubmit, setOnSubmit] = useState({message: "Download now", isSubmitted: false });
  const [emailAdd, hasEmail] = useState('')
  const [name, hasName] = useState('')
  const [company, hasCompany] = useState('')
  const [error, isError] = useState()
  const [disabled, isDisabled] = useState(true)

  const handleChange = event => {
    hasEmail(event.target.value)
    isDisabled(false)
    setOnSubmit({message: "Download now", isSubmitted: false });
  }

  const handleChangeName = eventi => {
    hasName(eventi.target.value)
    isDisabled(false)
    setOnSubmit({message: "Download now", isSubmitted: false });
  }
  const handleChangeCompany = eventj => {
    hasCompany(eventj.target.value)
    isDisabled(false)
    setOnSubmit({message: "Download now", isSubmitted: false });
  }
  
  const handleSubmit = event => {
    event.preventDefault();

    const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const result = pattern.test(emailAdd);

    if (result === true) {
      isError(false)
      isDisabled(true)
      setOnSubmit({message: "Sending...", isSubmitted: false });

      axios({
        method: 'post',
        url: `https://cors-anywhere.herokuapp.com/https://api.mailerlite.com/api/v2/groups/103047540/subscribers`,
        headers: { 'content-type': 'application/json', 'X-MailerLite-ApiKey': 'f13b6f484a31a41bbdefdd972b1d6099'  },
        data: {
          email: emailAdd,
          name: name,
          type: 'active',
          fields: {
            company: company
          }
        }
      }).then(res=> {
          console.log('Success sending email')
          setOnSubmit({message: "Thanks! We've emailed your eBook to you.", isSubmitted: true });
          hasEmail('');
          hasName('');
          hasCompany('');
      }).catch((error) => {
        setOnSubmit({message: "Ops! Please try again.", isSubmitted: false });
      })
    } else (
      isError(true)
    )
  };

  return (
    <Layout>
      <SEO 
        title="The Subscription Economy in 2020 | Free eBook" 
        description="Download our free eBook about The Subscription Economy in 2020 and learn more about managing subscription billing and recurring payments." 
        url="https://www.billsby.com/resources/guides/your-essential-briefing-on-the-subscription-economy-in-2020"
      />

      <div className="ebook subscription">
        <div className="section-hero">
          <div className="container container-flex">
            <div className="section-content">
              <h1 className="section-heading">Your essential briefing on the subscription economy in 2020.</h1>
              <p className="section-text">Subscriptions are rapidly becoming vital and, in some cases, the primary part of a variety of industry models. This trend is expected to continue through 2020. Why is this occurring? Over the past few years, customers have shown they value usage as well as access compared with the ownership of products.</p>
              <p className="section-text">Companies are now tasked with rising to meet the growing demand. There are a variety of tools available to businesses that want to implement this option into their models. This includes analytics, data, and software. Businesses that do choose to proceed down this path benefit from increasing rewards sourced from investors with increasingly rich valuations.</p>

              <a className="btn-orange" href="#ebookForm">Download free eBook</a>
            </div>
            <div className="section-image">
              <Img filename="ebook-subscriptioneconomy.png" className="main-img" alt="Your essential briefing on the subscription economy in 2020" />
            </div>
          </div>
        </div>
        <div className="section-ebook w-full">
          <div className="container container-flex">
            <div className="ebook-content">
              <h2 className="section-heading">The Evolution Of The Subscription Economy</h2>
              <p className="section-text">It’s important to understand that the concept of subscriptions is not a new idea. In some form, this has been around for hundreds of years. Cars have been leased since the 1700s. The subscription itself was created in 1856 with a hire-purchase option for renting sewing machines.</p>
              <p className="section-text">However, the origins of the current subscription model date back to 1948. It was during this time that the market for US cable and satellite was born. Shortly after this, Netflix brought the concept forward with the concept of mail DVD rentals. By 1996, there were subscription services for flat rate internet from AOL and retail has been one of the most recent sectors to embrace this particular trend. Indeed, by 2019, Amazon Prime had a membership of over 101 million users which accounts for 62% of US customers alone.</p>
              <p className="section-text">Of course, the true winner of the subscription model up to this point has been the software industry. Software as a Service or SAAS became popular in the 2000s. Before this shift, many businesses and customers were buying the software. SaaS revenues hit a staggering $85 billion through 2019.</p>
            </div>
          </div>
        </div>
        <div className="section-ebook w-full">
          <div className="container container-flex">
            <div className="ebook-content">
              <h2 className="section-heading">Subscriptions within the past decade</h2>
              <p className="section-text">Experts continue to debate whether subscriptions are a new evolution for the global economy or a passing fad.</p>
              <p className="section-text">Before exploring the answer to this question, it should be noted that subscriptions do provide more value than a typical lease set up. Even in the car industry, there is greater value. With Porsche’s Passport program, it’s possible for users to switch between different vehicles 18 times per year. Subscriptions can also be provided on a month to month basis compared with leases that are often built for the long term. As well as this, in the case of the car industry, subscriptions will include insurance as part of the package. This once again, ensures that customers are getting greater value and provides security for the brand.</p>
              <p className="section-text">The subscription economy is now benefiting both individual brands in different sectors such as Dollar Shave Club and Peloton as well as vendors. Vendors gain benefits from subscriptions with improved financial valuations. When this occurs, more companies are likely to adopt the model. By 2013, Adobe had stopped boxed packages completely and was committed to a SAAS business model.</p>
              <p className="section-text">Through this change, the businesses saw significant gains with shares increasing from $1.66 up to $5.2 from 2012 to 2018. Overall, the business witnessed a 700% increase in the price of its shares. In other cases, businesses have been introduced and then purchased for substantial profits. In 2016 Unilever purchased Dollar Shave Club for $1 billion. This occurred just five years after the initial launch of the business.</p>
            </div>
          </div>
        </div>
        <div id="ebookForm" className="ebook-form">
          <div className="container">
            <div className="form-wrapper container-flex">
              <form>
                <h2 className="section-heading">Download our free eBook today to learn the rest of our ways to deliver great customer service</h2>

                <div className="form-group">
                  <input type="text" className={`input-field ${error ? 'error' : ''}`} placeholder="Enter you name" name="name" value={name} onChange={handleChangeName} />
                  
                  <input type="email" className={`input-field ${error ? 'error' : ''}`} placeholder="Enter your email address" name="emailAdd" value={emailAdd} onChange={handleChange}/>

                  <input type="text" className={`input-field ${error ? 'error' : ''}`} placeholder="Enter your company name" name="companyName" value={company} onChange={handleChangeCompany}/>

                  <button type="submit" className={ `btn-blue ${onSubmit.isSubmitted ? 'btn-submitted' : ''}` } onClick={ handleSubmit } disabled={disabled}>{ onSubmit.message }</button>

                  <p className="section-text">You'll be signed up for marketing communications from Billsby. We respect your <Link to="/privacy">privacy</Link>.</p>
                </div>

              </form>
              <div className="brochure-holder">
                <Img filename="ebook-subscriptioneconomy.png" className="main-img" alt="Your essential briefing on the subscription economy in 2020" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Churn